import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import Header from '../shared/Header'
import { media } from '../utils/style-utils'

import IdSVG from '../shared/image-components/portcos/IdSVG'
import GrummiesSVG from '../shared/image-components/portcos/GrummiesSVG'
import FinnSVG from '../shared/image-components/portcos/FinnSVG'
import WellpathSVG from '../shared/image-components/portcos/WellpathSVG'
import DojomojoSVG from '../shared/image-components/portcos/DojomojoSVG'

const Container = styled.div`
  background-color: ${props => props.theme.colors.gandalf};
  
  width: 100%;
  display: grid;
  place-items: center;

  ${media.small`
    padding: 70px 20px 55px 20px;
  `}
  ${media.medium`
    padding: 100px 40px 55px 40px;
  `}
  ${media.large`
    padding: 90px 40px;
    height: 540px;
  `}
`

const ButtonGrid = styled.div`
  display: grid;
  grid-auto-flow: column;

  ${media.small`
    grid-auto-columns: 60px;
    grid-gap: 10px;
    margin: 26px auto 0 auto;
  `}
  ${media.large`
    grid-auto-columns: 111px;
    grid-gap: 20px;
    margin: 26px auto 0 auto;
  `}
`

const swing = keyframes`
  20% { transform: rotate(15deg); } 
  40% { transform: rotate(-10deg); } 
  60% { transform: rotate(5deg); } 
  80% { transform: rotate(-5deg); } 
  100% { transform: rotate(0deg); } 
`

const ButtonWrapper = styled.div`
  visibility: hidden;
  outline: none;

  cursor: pointer;

  & svg {
    ${media.small`
      height: 63px;
      width: 63px;
    `}
    ${media.large`
      height: 111px;
      width: 111px;
    `}

  
    & circle {
      fill: ${props =>
        props.active ? props.theme.colors.healerite : props.theme.colors.azure};
    }

    & path {
      fill: ${props =>
        props.dojo
          ? ''
          : props.active
          ? props.theme.colors.azure
          : props.theme.colors.white};
    }
  }

  &:hover {
    animation: ${swing} 1s;

    & svg {
      & circle {
        fill: ${props => props.theme.colors.healerite};
      }

      & path {
        fill: ${props => (!props.dojo ? props.theme.colors.azure : '')};
      }
    }
  }
`

const AboveTheFold = ({ filteredBrand, setFilteredBrand, brandNames }) => {
  const headerConfig = {
    header: 'Word on the street',
    subheader:
      'Okay, not quite the street, but here’s what they’re saying about us across the web',
    fontColor: props => props.theme.colors.azure,
    centered: true,
    maxWidth: 1000,
  }

  const buttons = [
    { id: 'id', component: <IdSVG /> },
    { id: 'grummies', component: <GrummiesSVG /> },
    { id: 'finn', component: <FinnSVG /> },
    { id: 'wellpath', component: <WellpathSVG /> },
    { id: 'dojomojo', component: <DojomojoSVG /> },
  ]

  // Dynamically determines which filter buttons should show up based
  // on which press articles are fetched from CMS
  const displayedButtons = buttons.filter(button =>
    brandNames.includes(button.id)
  )

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const tl = gsap.timeline()
    tl.to('.press_filter-buttons', { visibility: 'visible' })
    tl.from('.press_filter-buttons', {
      scrollTrigger: '.press_filter-buttons',
      stagger: 0.15,
      opacity: 0,
      duration: 0.75,
      y: 20,
    })
  }, [])

  const handleFilterClick = id => {
    const tl = gsap.timeline()
    tl.to('.press__press-group', { opacity: 0, duration: 0.35 })
    tl.call(() => setFilteredBrand(id), null, '<0.25')
    tl.to('.press__press-group', { opacity: 1, duration: 2, delay: 0.5 })
  }

  return (
    <Container>
      <Header config={headerConfig} />
      <ButtonGrid>
        {displayedButtons.map((button, idx) => (
          <ButtonWrapper
            dojo={button.id === 'dojomojo'}
            active={filteredBrand === button.id}
            onClick={() => handleFilterClick(button.id)}
            className="press_filter-buttons"
            key={`logo-${idx}`}
          >
            {button.component}
          </ButtonWrapper>
        ))}
      </ButtonGrid>
    </Container>
  )
}

export default AboveTheFold

