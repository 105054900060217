import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../../components/shared/SEO'
import Layout from '../../components/Layout'
import Press from '../../components/Press'

const PressPage = ({ location, data }) => {
  const pressList = data.allMarkdownRemark.edges.map(
    edge => edge.node.frontmatter.articles
  )[0]


  return (
    <>
      <SEO
        title="Our Press"
        description="Word on the street. Okay, not quite the street, but here’s what they’re saying about us across the web."
      />
      <Layout location={location}>
        <Press pressList={pressList} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query Press {
    allMarkdownRemark(
      filter: {
        frontmatter: { articles: { elemMatch: { visible: { eq: true } } } }
      }
    ) {
      edges {
        node {
          frontmatter {
            articles {
              website
              quote
              title
              visible
              brand
              logo {
                childImageSharp {
                  fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PressPage
