import React, { useState } from 'react'
import styled from 'styled-components'

import AboveTheFold from './AboveTheFold'
import PressGrid from './PressGrid'

const Container = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
`

const Press = ({ pressList }) => {
  const [filteredBrand, setFilteredBrand] = useState('id')

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index
  }

  const brandNames = pressList
    .map(article => article.brand.toLowerCase())
    .filter(onlyUnique)

  return (
    <Container>
      <AboveTheFold
        filteredBrand={filteredBrand}
        setFilteredBrand={setFilteredBrand}
        brandNames={brandNames}
      />
      <PressGrid pressList={pressList} filteredBrand={filteredBrand} />
    </Container>
  )
}

export default Press
