import React, { useEffect } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { gsap } from 'gsap'

import { H1, LargeP } from './text'
import { media } from '../utils/style-utils.js'

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: 20px;

  color: ${props => props.fontColor || props.theme.colors.white};

  ${media.large`
    grid-row-gap: 6px;
    max-width: 1268px;
  `}

  & h1 {
    line-height: 1.3;

    ${media.large`
      margin-bottom: 30px;
    `}
  }

  & h1,
  p {
    visibility: hidden;
    text-align: ${props => (props.centered ? 'center' : 'left')};

    ${media.large`
      max-width: ${props => `${props.maxWidth}px` || 'none'};
      margin: ${props => (props.maxWidth && props.centered ? '0 auto' : '0')};
    `}
  }
`

const ImageContainer = styled.div`
  overflow: hidden;

  ${media.small`
    height: 226px;
    width: 100%;
  `}
  ${media.medium`
    height: 400px;
  `}
  ${media.large`
    height: 500px;
  `}

  & > div {
    visibility: hidden;
  }
`

const Header = ({ config }) => {
  const includesImage = Boolean(config.image)

  useEffect(() => {
    const tl = gsap.timeline({ delay: 0.3 })
    tl.to('.header__copy', {
      autoAlpha: 1,
      duration: 0.5,
      stagger: 0.15,
      y: -30,
    })
    if (includesImage) {
      tl.to('.header__image', { autoAlpha: 1, duration: 2, scale: 1.03 })
    }
  }, [includesImage])

  return (
    <Container
      fontColor={config.fontColor}
      maxWidth={config.maxWidth}
      centered={config.centered}
    >
      <H1 className="header__copy">{config.header}</H1>
      <LargeP className="header__copy">{config.subheader}</LargeP>
      {includesImage && (
        <ImageContainer>
          <Img
            className="header__image"
            fluid={config.image.fluid}
            alt={config.image.alt}
            style={{ height: '100%', width: '100%' }}
          />
        </ImageContainer>
      )}
    </Container>
  )
}

export default Header
