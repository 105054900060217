import React from 'react'

export default () => (
  <svg
    width="112"
    height="111"
    viewBox="0 0 112 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="55.918" cy="55.5" r="55.5" fill="#1325A9" />
    <path
      d="M25.4088 47.5208V53.2223C26.3605 50.3554 28.6434 46.9022 31.5714 46.9022V54.6939H25.4088V73.7713H16.1635V54.6939H12.4648V46.9022H16.1635C16.1635 41.271 17.8275 37 25.9022 37C27.8143 37 29.6622 37.1847 31.5743 37.6185V44.4281H29.6009C26.6408 44.4281 25.4088 45.0467 25.4088 47.5208Z"
      fill="white"
    />
    <path d="M43.0636 46.9023H33.8184V73.7714H43.0636V46.9023Z" fill="white" />
    <path
      d="M33.8223 41.1567C33.8223 38.8263 35.9183 37 38.4464 37C40.9744 37 43.0705 38.8263 43.0705 41.1567C43.0705 43.3611 40.9744 45.1903 38.4464 45.1903C35.9183 45.1903 33.8223 43.364 33.8223 41.1567Z"
      fill="white"
    />
    <path
      d="M59.459 57.3028C59.459 56.0042 58.411 54.9518 57.1178 54.9518C55.8246 54.9518 54.7765 56.0042 54.7765 57.3028V73.7713H45.5312V46.9022H54.7765V54.09C55.7632 51.8621 57.9206 46.2837 61.9871 46.2837C67.6563 46.2837 68.7043 50.4287 68.7043 59.1554V73.7713H59.4619V57.3028H59.459Z"
      fill="white"
    />
    <path
      d="M85.1006 57.3028C85.1006 56.0042 84.0526 54.9518 82.7594 54.9518C81.4662 54.9518 80.4181 56.0042 80.4181 57.3028V73.7713H71.1758V46.9022H80.4181V54.09C81.4049 51.8621 83.5622 46.2837 87.6287 46.2837C93.2979 46.2837 94.3459 50.4287 94.3459 59.1554V73.7713H85.1036V57.3028H85.1006Z"
      fill="white"
    />
    <path
      d="M98.5064 71.9627C98.7078 71.9627 98.8742 71.863 98.8742 71.6461C98.8742 71.4233 98.7078 71.3295 98.5064 71.3295H98.0247V71.9627H98.5064ZM97.6277 70.9572H98.5239C98.9442 70.9572 99.28 71.2328 99.28 71.649C99.28 72.0536 98.9267 72.3086 98.5355 72.335L99.2332 73.1089L99.1603 73.2085H98.7983L98.0422 72.335H98.0276V73.2085H97.6277V70.9572ZM98.3458 73.657C99.204 73.657 99.8872 72.9975 99.8872 72.1034C99.8872 71.2152 99.2099 70.5498 98.3458 70.5498C97.4817 70.5498 96.8044 71.2152 96.8044 72.1034C96.8044 72.9975 97.4875 73.657 98.3458 73.657ZM98.3458 70.2068C99.3558 70.2068 100.235 71.0393 100.235 72.1034C100.235 73.1675 99.3442 74 98.3458 74C97.3357 74 96.457 73.1675 96.457 72.1034C96.457 71.0393 97.3386 70.2068 98.3458 70.2068Z"
      fill="white"
    />
  </svg>
)
