import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import ChevronDownSVG from '../shared/image-components/ChevronDownSVG'
import { FilterButton } from '../shared/buttons'
import { P, SmallP } from '../shared/text'
import { media } from '../utils/style-utils'

const Wrapper = styled.div`
  width: 100%;

  display: grid;
  place-items: center;

  min-height: 800px;
  padding-bottom: 100px;
`

const Grid = styled.div`
  width: 100%;

  display: grid;
  justify-items: center;

  color: ${props => props.theme.colors.azure};
  text-align: center;


  ${media.small`
    padding: 58px 50px 0 50px;
    grid-gap: 85px;  
  `}
  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
  `}
  ${media.large`
    grid-template-columns: repeat(3, 1fr);
    max-width: 1268px;
    padding: 80px 70px 0 70px;
    grid-gap: 90px 80px; 
  `}
`

const PressGroup = styled.div`
  width: 100%;
  height: fit-content;

  display: grid;
  place-items: center;

  ${media.small`
    grid-row-gap: 10px;
  `}
  ${media.medium`
    grid-row-gap: 20px;
  `}
  ${media.large`
    grid-row-gap: 30px;

    grid-column: ${props => (props.singleArticle ? 2 : '')};
  `}
`

const IconImg = styled.div`
  height: 100%;
  width: 100%;

  max-width: 300px;
  max-height: 52px;
`

const ButtonP = styled(P)`
  display: flex;
  align-items: center;

  & svg {
    margin-left: 9px;
  }
`

const PressGrid = ({ pressList, filteredBrand }) => {
  const [filteredPress, setFilteredPress] = useState([])
  const [clickedMore, setClickedMore] = useState(false)

  useEffect(() => {
    const filtered = pressList.filter(
      article => article.brand?.toLowerCase() === filteredBrand
    )
    setFilteredPress([...filtered])
  }, [filteredBrand, pressList])

  const displayedArticleCount = 9
  const sliceIdx = clickedMore ? filteredPress.length : displayedArticleCount
  const displayedArticles = filteredPress.slice(0, sliceIdx)

  return (
    <Wrapper>
      <Grid>
        {displayedArticles.map(press => {
          return (
            <PressGroup
              className="press__press-group"
              singleArticle={filteredPress.length === 1}
              key={press.title}
            >
              <IconImg
                as={press.website ? 'a' : 'div'}
                href={press.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={press.logo.childImageSharp.fluid}
                  style={{ height: '100%', width: '100%' }}
                  imgStyle={{ objectFit: 'contain' }}
                  alt={press.title}
                />
              </IconImg>
              <SmallP>{press.quote}</SmallP>
            </PressGroup>
          )
        })}
      </Grid>
      {!clickedMore && filteredPress.length > displayedArticleCount && (
        <FilterButton onClick={() => setClickedMore(true)}>
          <ButtonP>
            read more <ChevronDownSVG />
          </ButtonP>
        </FilterButton>
      )}
    </Wrapper>
  )
}

export default PressGrid
