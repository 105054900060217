import React from 'react'

export default () => (
  <svg
    width="112"
    height="111"
    viewBox="0 0 112 111"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="56.127" cy="55.5" r="55.5" fill="#1325A9" />
    <path
      d="M31.8492 51.2829L29.9317 62.6303H27.9305L27.0985 56.733L26.1978 62.6303H24.264L22.4727 51.2829H24.755L25.5207 58.1791L26.3602 51.2829H28.1503L28.9173 58.1791L29.7543 51.2829H31.8492Z"
      fill="white"
    />
    <path
      d="M39.8805 62.6291H34.9336V51.2829H39.8805V53.2052H37.2959V55.9176H38.9898V57.6904H37.2959V60.6353H39.8805V62.6291Z"
      fill="white"
    />
    <path
      d="M48.2635 62.6291H43.459V51.2829H45.8225V60.7081H48.2647V62.6303H48.2635V62.6291Z"
      fill="white"
    />
    <path
      d="M56.4529 62.6291H51.6484V51.2829H54.012V60.7081H56.4542L56.4529 62.6291Z"
      fill="white"
    />
    <path
      d="M65.4677 56.1136C65.4677 56.8712 65.2441 57.4403 64.7969 57.8185C64.3509 58.1967 63.795 58.3864 63.1304 58.3864H62.2073V62.6291H59.8438V51.2829H63.1467C63.8063 51.2829 64.3584 51.4739 64.8031 51.8596C65.2478 52.244 65.4677 52.8094 65.4677 53.5569V56.1136ZM63.2054 56.1651V53.5796C63.2054 53.2341 63.0655 53.0607 62.7856 53.0531H62.2073V56.6639H62.7856C63.0655 56.6652 63.2054 56.4981 63.2054 56.1651Z"
      fill="white"
    />
    <path
      d="M74.2218 62.6292H71.8683L71.7084 60.8062H70.318L70.1206 62.6292H68.002L69.7758 51.2817H72.4766L74.2218 62.6292ZM71.5035 59.036L71.1025 54.6186L70.5766 59.036H71.5035Z"
      fill="white"
    />
    <path
      d="M82.3623 53.2793H80.7146V62.6303H78.3536V53.2793H76.6934V51.2829H82.3623V53.2793Z"
      fill="white"
    />
    <path
      d="M91.7881 62.6291H89.427V58.1037H88.0979V62.6291H85.7344V51.2829H88.0979V56.1073H89.427V51.2829H91.7881V62.6291Z"
      fill="white"
    />
    <path
      d="M103.022 71.4186H11.752V47.5403H13.4109V69.8696H101.364V43.5388H12.5802V41.991H103.022V71.4186Z"
      fill="white"
    />
    <path
      d="M12.5802 45.0879C13.9544 45.0879 15.0687 44.0476 15.0687 42.7649C15.0699 41.4821 13.9556 40.4419 12.5802 40.4419C11.2061 40.4419 10.0918 41.4821 10.0918 42.7649C10.0918 44.0476 11.2061 45.0879 12.5802 45.0879Z"
      fill="white"
    />
  </svg>
)
