import React from 'react'

export default () => (
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3938 0.396203C9.0359 1.75414 7.67778 3.11227 6.31984 4.47034C4.95861 3.10911 3.59719 1.74769 2.23596 0.386263C1.04876 -0.80112 -0.786842 1.04856 0.403327 2.23873C2.07009 3.9055 3.73667 5.57227 5.40344 7.23903C5.90697 7.74276 6.74609 7.72901 7.24582 7.22914C8.91258 5.56237 10.5792 3.8956 12.2459 2.22884C13.4335 1.0415 11.5838 -0.794106 10.3938 0.396203Z"
      fill="#1325A9"
    />
  </svg>
)
